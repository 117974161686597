import { modifyPsw, postModifyPswByMsg, postUserLogin, sendVerify, getVerifyPic } from "@/service/userManagement/index";
export default {
  props: {
    //用户编号
    userId: {
      type: String,
      default: ""
    },
    closeFlag:{
      type:Boolean,
      default:true
    }
  },
  name:'ModifyPassword',
  //修改密码
  data() {
    return {
      form: this.$form.createForm(this),
      verifyPicform: this.$form.createForm(this),
      modyForm: this.$form.createForm(this),
      pwd: {
        lost: false,
        phone: sessionStorage.getItem('userPhone'),
        ispost: false,
        unitId: sessionStorage.getItem('unitId'),
        userName: sessionStorage.getItem('loginName'),
      },
      current: 0,
      number: 0,
      picTimer: null,
      phoneTimer: null,
      getPwdNum: 0,
      steps: [ {
        title: '中'
      }, {
        title: '强'
      }],
      verifyPic: {
        userName: sessionStorage.getItem('userName'),
        phone: ''
      },
      getVerify: {
        visible: false,
        isSearch: false,
        reqId: '',
        verifyCode: ''
      },
      verifyParams: {},
      newPwdFlag: {
        delubukejian: true, //密码显示隐藏
        password: 'password', //input 密码隐藏
      },
      confirmPwdFlag:{
        delubukejian: true, //密码显示隐藏
        password: 'password', //input 密码隐藏
      }
    };
  },
  methods: {
    changeType(paramFlag,param) {
      // if (!this.modyForm.getFieldValue(param)) {
      //   this.delubukejian = false
      //   this.password = 'text'
      // } else {
      //   this.delubukejian = true
      //   this.password = 'password'
      // }
      if(paramFlag===1) {
        this.newPwdFlag.delubukejian = !param
        this.newPwdFlag.password = param?'text':'password'
      } else {
        this.confirmPwdFlag.delubukejian = !param
        this.confirmPwdFlag.password = param?'text':'password'
      }
    },
    //自定义校验方法
    handleValidator(rule, value, callback) {
      let  formValue ='';
      if(this.pwd.lost){
        formValue=this.form.getFieldValue("newPwd")
      }else{
        formValue=this.modyForm.getFieldValue("newPwd")
      }
      if (!value) {
        callback("请输入确认密码");
      } else if (formValue !== value) {
        callback("确认密码与新密码不一致");
      } else {
        callback();
      }
    },
    //修改密码
    handleModifyPassword(e) {
      e.preventDefault();
      if (!this.pwd.lost) {
        this.modyForm.validateFields((err, values) => {
          if (!err) {
            let params;
            if (this.userId) {
              params = {
                userId: this.userId,
                newPsw: values.newPwd,
                oldPsw: values.oldPwd,
                unitId: this.pwd.unitId,
                userName: sessionStorage.getItem('userName'),
              };
              modifyPsw(params).then(res => {
                if (!res.result) {
                  this.handleCloseModifyPassword();
                  this.$message.success("恭喜你，密码修改成功，下次登录生效");
                  this.$router.push({ path: '/' });
                  this.$store.dispatch('user/loginOut')
                } else {
                  this.form.setFieldsValue({ confirmPwd: "" });
                  this.$message.error("密码修改失败，原因：" + res.message);
                }
              })
            }
          }
        });
      } else {
        this.form.validateFields((err, values) => {
          if (!err) {
            let params;
            if (this.userId) {
              params = {
                userId: this.userId,
                newPsw: values.newPwd.trim(),
                unitId: this.pwd.unitId,
                userName: sessionStorage.getItem('userName'),
                verifyCode: values.phoneCode.trim()
              };
              postModifyPswByMsg(params).then(res => {
                if (!res.result) {
                  this.handleCloseModifyPassword();
                  this.$message.success("密码修改成功，请重新登录");
                  this.$store.dispatch('user/loginOut');
                } else {
                  this.form.setFieldsValue({ confirmPwd: "" });
                  this.$message.error("密码修改失败，原因：" + res.message);
                }
              })
            }
          }
        })
      }
    },
    //关闭修改密码框
    handleCloseModifyPassword(e) {
      if (e) {
        e.preventDefault();
      }
      this.pwd.lost = false;
      this.resetForm();
      if(this.closeFlag) {
        this.$emit("evt-receive");
      }
    },
    handleCloseVerifyPicform() {
      this.getVerify.visible = false;
      this.handleFormFieldsReset(this.modyForm);
      
    },
    handleverifyPicform() {
      this.verifyPicform.validateFields((err, values) => {
        if (!err) {
          this.verifyParams = values;
          this.getVerifyPic(values)
        }
      });
    },
    getVerifyPic(values) {
      if (this.number <= 3) {
        getVerifyPic(values).then(res => {
          if (!res.message) {
            if (res.data) {
              this.getVerify.reqId = res.data.reqId;
              this.getVerify.verifyCode = res.data.verifyCode;
              this.getVerify.isSearch = true;
              this.number++;
              clearTimeout(this.phoneTimer);
              this.handleCloseVerifyPicform();
            }
          } else {
            this.getVerify.reqId = '';
            this.getVerify.verifyCode = ''
            // this.getVerify.isSearch = true
            this.$message.info(`获取验证码失败，原因是：${res.message}`)
          }

        })
      } else {
        this.$message.info('操作频繁,请一分钟之后再试');
        this.phoneTimer = setTimeout(() => {
          this.number = 0
        }, 60000)
      }
    },
    // 忘记密码
    fogetPwd() {
      this.current = 0;
      this.getVerify.visible = true;
      return
    },
    // 发送验证码
    sendVerify() {
      if (!this.form.getFieldValue('verifyCode')) {
        this.$message.info('请先输入图形验证码 !');
        return;
      }
      const params = { 'phone': this.pwd.phone, reqId: this.getVerify.reqId, verifyCode: this.form.getFieldValue('verifyCode') }
      if (this.getPwdNum == 0) {
        sendVerify(params).then(res => {
          if (!res.message) {
            this.getPwdNum++;
            clearTimeout(this.picTimer);
            // let sec = 30;
            // $('.timer').html('');
            // $('.times').html(sec);
            // this.times(sec);
          } else {
            this.$message.error(res.message)
          }
        });
      } else {
        this.$message.info('操作频繁,请一分钟之后再试');
        this.picTimer = setTimeout(() => {
          this.getPwdNum = 0
        }, 60000)
      }

    },
    // 倒计时
    times(sec) {
      $('.times').html(sec);
      sec--;
      setTimeout(() => {
        if (sec > 0) {
          this.times(sec);
        } else {
          $('.timer').html('重新发送');
          $('.times').html('');
        }
      }, 1000);
    },
    // 获取输入框内容
    getPwd(e) {
      var regx = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@$%\^\-_=+\[\{\}\]\:,.\/\?~#\*]+$)(?![a-z0-9]+$)(?![a-z\\W_!@$%\^\-_=+\[\{\}\]\:,.\/\?~#\*]+$)(?![0-9\\W_!@$%\^\-_=+\[\{\}\]\:,.\/\?~#\*]+$)[a-zA-Z0-9\\W_!@$%\^\-_=+\[\{\}\]\:,.\/\?~#\*]{8,26}$/;
      let value = e.target.value;
      // if (value.length >= 12) {
      //   this.current = 1;
      //   if (value.match(regx)) {
      //     this.current = 2;
      //   } else {
      //     this.current = 1;
      //   }
      // } else {
      //   this.current = 0;
      // }
      if (regx.test(value)) { 
        this.current = 2;
      } else {
        this.current = 1;
      }
    },
    resetForm(){
        this.handleFormFieldsReset(this.verifyPicform);
        this.handleFormFieldsReset(this.modyForm);
        this.getVerify.isSearch = false;
        this.current = 0;
    }
  },
  mounted() {
    // console.log('1111');
  }
};
